import { useContext, useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import { observer } from 'mobx-react'

import { routes } from '../routes'
import { RootStoreContext } from '../store/Store'

import Page from '../components/Page'
import './DocusignConfig.css'

export interface IState {
  [key: string]: any,
}

export default observer(({
  ...props
}) => {

  const store = useContext(RootStoreContext)

  const defaultState = {
    loaded: false,
    completed: false,
    redirectToHome: false,
    jwtURL: "",
  }

  const [formState, setFormState] = useState<IState>(defaultState)

  useEffect(() => {
    if (store.sessionStore.checkedSessionOnLoad) {
      if (!store.sessionStore.isAuthenticated) {
        setFormState({
          ...formState,
          redirectToHome: true,
          loaded: true,
        })
      } else {
        if (!formState.loaded) {
          store.apiStore.getDocusignJWTURL().then((data) => {
            setFormState({
              ...formState,
              loaded: true,
              jwtURL: data.url,
            })
          }).catch((err) => {
            console.log("Error getting Docusign JWT URL")
          })
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.sessionStore.checkedSessionOnLoad, store.sessionStore.isAuthenticated, formState.loaded])

  if ( formState.redirectToHome ) {
    return <Redirect to={routes.home.path} />
  }

  return (
    <Page
      title={routes.docusignConfig.label}
      iconClasses={ routes.docusignConfig.icon }
      {...props}
    >
      Click <a href={formState.jwtURL}>here</a> to allow Mossmont Portal to connect to Docusign.
    </Page>
  )
})
