import { useContext, useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import { observer } from 'mobx-react'
import queryString from 'query-string'

import { routes } from '../routes'
import { RootStoreContext } from '../store/Store'

import Page from '../components/Page'
import './DocusignSuccess.css'

export interface IState {
  [key: string]: any,
}

export default observer(({
  ...props
}) => {

  const store = useContext(RootStoreContext)

  const [formState, setFormState] = useState<IState>({
    loaded: false,
    completed: false,
    redirectToHome: false,
    success: false,
  })

  const {
    code: JWTCode,
  } = queryString.parse(window.location.search)

  useEffect(() => {
    if (store.sessionStore.checkedSessionOnLoad) {
      if (!store.sessionStore.isAuthenticated) {
        setFormState({
          ...formState,
          redirectToHome: true,
          loaded: true,
        })
      } else {
        if (!formState.loaded) {
          if (!formState.completed) {
            setFormState({
              ...formState,
              loaded: true,
            })
            store.apiStore.updateDocusignJWTCode(String(JWTCode)).then(() => {
              setFormState({
                ...formState,
                loaded: true,
                completed: true,
                success: true,
              })
            }).catch((err) => {
              setFormState({
                ...formState,
                loaded: true,
                completed: true,
              })
              console.log(err)
            })
          }
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.sessionStore.checkedSessionOnLoad, store.sessionStore.isAuthenticated, formState.loaded])

  if ( formState.redirectToHome ) {
    return <Redirect to={routes.home.path} />
  }

  return (
    <Page
      title={routes.docusignSuccess.label}
      iconClasses={ routes.docusignSuccess.icon }
      {...props}
    >
      {!!formState.success && (
        <div className="alert alert-success" role="alert">
          <h4 className="alert-heading"><i className="fas fa-check"></i> Success!</h4>
          <p>You granted Mossmont Portal access to your Docusign account</p>
        </div>
      )}
      {!!formState.loaded && !!formState.completed && !formState.success && (
        <div className="alert alert-danger" role="alert">
          <h4 className="alert-heading"><i className="fas fa-times"></i> Error!</h4>
          <p>There was a problem registering Mossmont Portal with docusign</p>
        </div>
      )}
    </Page>
  )
})
