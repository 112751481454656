import { useContext, useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import { Redirect } from 'react-router-dom'

import { routes } from '../routes'
import { RootStoreContext } from '../store/Store'
import SearchTable from '../components/SearchTable'
import { BudCountHistoryTable } from '../tables/BudCountHistory'

import Page from '../components/Page'

export interface IState {
  [key: string]: any,
}

export default observer(({
  ...props
}) => {

  const store = useContext(RootStoreContext)

  const emptyFormState = {
    loaded: false,
    redirectToHome: false,
  }

  const [formState, setFormState] = useState<IState>(emptyFormState)

  useEffect(() => {
    if (store.sessionStore.checkedSessionOnLoad) {
      if (!store.sessionStore.isAuthenticated) {
        setFormState({
          ...formState,
          redirectToHome: true,
          loaded: true,
        })
      } else {
        if (!formState.loaded) {
          setFormState({
            ...formState,
            loaded: true,
          })
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.sessionStore.checkedSessionOnLoad, store.sessionStore.isAuthenticated, formState.loaded])

  if ( formState.redirectToHome ) {
    return <Redirect to={routes.home.path} />
  }

  return (
    <Page
      title={ routes.budCountHistory.label }
      iconClasses={ routes.budCountHistory.icon }
      {...props}
    >
      <SearchTable table={BudCountHistoryTable} />
    </Page>
  )
})
