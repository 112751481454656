import { makeAutoObservable } from "mobx"
import { omit } from "lodash"
import moment from "moment"

import { RootStore } from "./Store"

interface ISessionStore {
  [key: string]: any
}

export const sessionStoreProtectedAttrs = [
  "rootStore",
  "checkedSessionOnLoad",
  "isAuthenticated",
  "csrf",
]

class SessionStore implements ISessionStore {

  [key: string]: any;

  rootStore: RootStore

  checkedSessionOnLoad: boolean = false
  isAuthenticated: boolean = false
  isDataEntryUser: boolean = false
  csrf: string = ""

  first_name: string = ""
  last_name: string = ""
  email: string = ""

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, { rootStore: false })
    this.rootStore = rootStore
  }
  onRouteChange () {

  }

  setIsAuthenticated(isAuthenticated: boolean) {
    this.isAuthenticated = isAuthenticated
  }

  setCSRFToken(csrfToken: string) {
    this.csrf = csrfToken
  }

  setFirstName(firstName: string) {
    this.first_name = firstName
  }

  setLastName(lastName: string) {
    this.last_name = lastName
  }

  setEmail(email: string) {
    this.email = email
  }

  setUserData(data: { user: Record<string, any> }) {
    this.isAuthenticated = true
    Object.keys(data.user).filter(item => item !== 'date_of_birth' && item !== 'start_date').forEach((key, index) => {
      this[key] = data.user[key]
    })
    if (data.user.date_of_birth){
      this.date_of_birth = moment(data.user.date_of_birth).toDate()
    } else {
      this.date_of_birth = undefined
    }
    if (data.user.is_staff){
      this.isDataEntryUser = true
    } else {
      this.isDataEntryUser = false
    }
    if (data.user.start_date){
      this.start_date = moment(data.user.start_date).toDate()
    } else {
      this.start_date = undefined
    }
  }

  clearUserData() {
    this.isAuthenticated = false
    Object.keys(omit(this, sessionStoreProtectedAttrs)).forEach((key, index) => {
      this[key] = ""
    })
  }

}

export default SessionStore
