import React, { useState, useContext, SyntheticEvent } from 'react'
import { Redirect } from 'react-router-dom'
import { observer } from 'mobx-react'

import { RootStoreContext } from '../store/Store'
import { routes } from '../routes'
import Page from '../components/Page'

export default observer(
  ({ ...props }) => {

    const store = useContext(RootStoreContext)
    const [completed, setCompleted] = useState(false);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    if (store.sessionStore.checkedSessionOnLoad && store.sessionStore.isAuthenticated) {
      return (
        <Redirect to={routes.home.path} />
      )
    }

    const handleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setFirstName(event.target.value)
    }

    const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setLastName(event.target.value)
    }

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setEmail(event.target.value)
    }

    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setPassword(event.target.value)
    }

    const handleConfirmPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setConfirmPassword(event.target.value)
    }

    const handleRegister = (event: SyntheticEvent) => {
      event.preventDefault();
      if (password !== confirmPassword) {
        store.apiStore.setError("Password does not match Confirm Password.")
        return
      }
      store.apiStore.register({
        first_name: firstName,
        last_name: lastName,
        email,
        password,
      }).then((data) => {
        setFirstName(data.user.first_name)
        setLastName(data.user.last_name)
        setEmail(data.user.email)
        setCompleted(true)
      }).catch((err) => {
        store.apiStore.handleFormError(err, "There was an error registering your details.")
      })
    }

    return (
      <Page
        title={ routes.register.label }
        iconClasses={ routes.register.icon }
        {...props}
      >
        {!completed && (
          <form method="POST" onSubmit={handleRegister}>
            <div className="form-group required">
              <label htmlFor="id_first_name" className="control-label">First Name</label>
              <input id="id_first_name" name="first_name" className="form-control" required onChange={handleFirstNameChange} />
            </div>
            <div className="form-group required">
              <label htmlFor="id_last_name" className="control-label">Last Name</label>
              <input id="id_last_name" name="last_name" className="form-control" required onChange={handleLastNameChange} />
            </div>
            <div className="form-group required">
              <label htmlFor="id_email" className="control-label">Email</label>
              <input type="email" id="id_email" name="email" className="form-control" required onChange={handleEmailChange} />
            </div>
            <div className="form-group required">
              <label htmlFor="id_password" className="control-label">Password</label>
              <input type="password" id="id_password" name="password" className="form-control" required onChange={handlePasswordChange} />
            </div>
            <div className="form-group required">
              <label htmlFor="id_confirm_password" className="control-label">Confirm Password</label>
              <input type="password" id="id_confirm_password" name="confirm_password" className="form-control" required onChange={handleConfirmPasswordChange} />
            </div>
            <input type="submit" className='btn btn-primary' value="Register" />
          </form>
        )}
        {!!completed && (
          <div className="alert alert-info" role="alert">
            <h4 className="alert-heading"><i className="fas fa-check"></i> Success!</h4>
            <p>You have registered your account with the following details.</p>
            <ul>
              <li>First Name - {firstName}</li>
              <li>Last Name - {lastName}</li>
              <li>Email - {email}</li>
            </ul>
            <hr />
            <p><strong><i className="fas fa-exclamation-circle"></i> Warning</strong> You must send the following forms to Mossmont staff before you are granted access:</p>
            <ul>
              <li><a target="_blank" rel="noreferrer" href="https://db.mossmont.com.au/tfn_form-mossmont_nurseries.pdf">TFN Form (link)</a> - Print, fill out and sign this form, then give to Mossmont staff.</li>
              <li><a target="_blank" rel="noreferrer" href="https://db.mossmont.com.au/piece_work_agreement-mossmont_nurseries.pdf">Piece Work Agreement (link)</a> - Print, fill out and sign this form, then give to Mossmont staff.</li>
              <li><a target="_blank" rel="noreferrer" href="https://db.mossmont.com.au/induction_booklet-mossmont_nurseries.pdf">Worker Induction Booklet (link)</a> - Print and read this.</li>
            </ul>
            <p>You will have to be approved by Mossmont staff before you can login or update your details. Fill out your forms ASAP. They will contact you saying when your account is ready.</p>
          </div>
        )}
      </Page>
    )
})
