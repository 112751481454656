import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

export const DateWidget = ({
  id = 'id_date_widget',
  name = 'date_widget',
  value = undefined,
  dateFormat = 'dd/MM/yyyy',
  placeholder = 'dd/mm/yyyy',
  className = 'form-control',
  wrapperClassName = 'form-control',
  onChange = (date: Date) => {},
  showMonthDropdown = true,
  showYearDropdown = true,
  scrollableYearDropdown = true,
  required = false,
  ...props
}) => {
  return (
    <DatePicker
      selected={value}
      showMonthDropdown={showMonthDropdown}
      showYearDropdown={showYearDropdown}
      scrollableYearDropdown={scrollableYearDropdown}
      onChange={onChange}
      wrapperClassName={wrapperClassName}
      className={className}
      dateFormat={dateFormat}
      id={id}
      name={name}
      required={required}
      placeholderText={placeholder}
      autoComplete="off"
    />
  )
}

export default DateWidget
