import React, {Fragment, useContext} from 'react'
import AsyncSelect from 'react-select/async'
import { WindowedMenuList } from 'react-windowed-select'
import { RootStoreContext } from '../store/Store'


export interface ILookupProps {
  lookup: string
  params?: object
  label: string
  id: string
  name: string
  value?: {
    label: string
    value: string
  }
  defaultValue?: string
  isClearable?: boolean
  onChange: Function
}

export default function Lookup({
  lookup,
  params = {},
  label,
  id,
  name,
  value,
  defaultValue,
  isClearable = true,
  onChange,
  ...props
}: ILookupProps) {

  const store = useContext(RootStoreContext)

  const loadOptions = (
    inputText: string,
    callback: (options: { label: string; value: string; }[]) => void
  ): void => {
    store.apiStore.getLookup(lookup, {search: inputText, ...params}).then(data => {
      const foundDefaultValue = data.find((item: {value: string, label: string}) => {
        return item.label === defaultValue
      })
      callback(data)
      if (foundDefaultValue) {
        onChange(foundDefaultValue)
      }
    }).catch((e) => {
      console.log(e)
    })
  }

  const handleChange = (selected?: {label: string, value: string} | {label: string, value: string}[] | null) => {
    onChange(selected)
  }

  return (
    <Fragment>
      <label htmlFor={id} className="control-label">{label}</label>
      <AsyncSelect
        components={{ MenuList: WindowedMenuList }}
        id={id}
        name={name}
        value={value}
        isClearable={isClearable}
        cacheOptions
        defaultOptions
        loadOptions={loadOptions}
        onChange={handleChange}
      />
    </Fragment>
  )
}
