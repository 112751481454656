import { ReactNode, useContext } from 'react'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react'
import { toJS } from 'mobx'

import { RootStoreContext } from '../store/Store'
import './Page.css'

interface IProps {
  children?: ReactNode
  footerSlot?: ReactNode
  iconClasses?: string
  title: string
  backUrl?: string
}

export default observer(({
  children,
  title = 'Page Default',
  iconClasses = '',
  footerSlot,
  backUrl = undefined,
  ...props
}: IProps) => {
  const store = useContext(RootStoreContext)
  const errors = toJS(store.apiStore.errors)
  return (
    <div className="content-wrapper px-4 py-2">
      <div className="card">
        <div className="card-header">
          <h2>
            {!!iconClasses && (
              <i className={`page-icon ${iconClasses}`}></i>
            )}
            {title} {!!backUrl && <Link to={backUrl} className="page-back-url"><i className="fas fa-arrow-left"></i> back</Link>}
          </h2>
          {!!store.apiStore.isBusy && (
            <div className="spinner-border page-spinner" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          )}
        </div>
        <div className="card-body">
        {!!errors && Array.isArray(errors) && errors.length > 0 && (
          <div className="alert alert-danger" role="alert">
            <h4 className="alert-heading"><i className="fas fa-exclamation-circle"></i> Errors</h4>
            <ul className='error-ul'>
            {errors.map((error) => {
              return (
                <li key={error}>
                  {error}
                </li>
              )
            })}
            </ul>
          </div>
        )}
          {children}
        </div>
        {!!footerSlot && (
          <div className="card-footer">
            {footerSlot}
          </div>
        )}
      </div>
    </div>
  )
})
