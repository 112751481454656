import { useContext, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react'

import { routes } from '../routes'
import { RootStoreContext } from '../store/Store'

import Page from '../components/Page'
import HomeBox from '../components/HomeBox'
import './Home.css'

export default observer(({
  ...props
}) => {
  const store = useContext(RootStoreContext)
  return (
    <Page
      title="Mossmont Portal"
      {...props}
    >
      {store.sessionStore.checkedSessionOnLoad && !store.sessionStore.isAuthenticated && (
        <Fragment>
          <HomeBox
            heading={<Fragment><h3><Link to={routes.register.path}>{routes.register.label}</Link></h3></Fragment>}
            description={<Fragment>If you do not have an account, register <Link to={routes.register.path}>here</Link></Fragment>}
            icon={routes.register.icon}
          />
          <HomeBox
            heading={<Fragment><h3><Link to={routes.login.path}>{routes.login.label}</Link></h3></Fragment>}
            description={<Fragment>If you already have an account, login <Link to={routes.login.path}>here</Link></Fragment>}
            icon={routes.login.icon}
          />
        </Fragment>
      )}
      {store.sessionStore.checkedSessionOnLoad && store.sessionStore.isAuthenticated && (
        <Fragment>
          <HomeBox
            heading={<Fragment><h3><Link to={routes.details.path}>{routes.details.label}</Link></h3></Fragment>}
            description={<Fragment>Update your employee details, including Australian Super info to start receiving payslips, click <Link to={routes.details.path}>here</Link></Fragment>}
            icon={routes.details.icon}
          />
          {store.sessionStore.isDataEntryUser && (
            <Fragment>
              <HomeBox
                heading={<Fragment><h3><Link to={routes.budCountSearch.path}>{routes.budCountSearch.label}</Link></h3></Fragment>}
                description={<Fragment>Enter bud counts, click <Link to={routes.budCountSearch.path}>here</Link></Fragment>}
                icon={routes.budCountSearch.icon}
              />
              <HomeBox
                heading={<Fragment><h3><Link to={routes.budCountHistory.path}>{routes.budCountHistory.label}</Link></h3></Fragment>}
                description={<Fragment>Check bud count history, click <Link to={routes.budCountHistory.path}>here</Link></Fragment>}
                icon={routes.budCountHistory.icon}
              />
            </Fragment>
          )}
          {!!store.sessionStore.is_superuser && (
            <Fragment>
              <h3>Administration</h3>
              <HomeBox
                heading={<Fragment><h3><Link to={routes.createUser.path}>{routes.createUser.label}</Link></h3></Fragment>}
                description={<Fragment>Create users to enter bud count or administration users, click <Link to={routes.createUser.path}>here</Link></Fragment>}
                icon={routes.createUser.icon}
              />
              <HomeBox
                heading={<Fragment><h3><Link to={routes.users.path}>{routes.users.label}</Link></h3></Fragment>}
                description={<Fragment>List bud count or administration users, click <Link to={routes.users.path}>here</Link></Fragment>}
                icon={routes.users.icon}
              />
              <HomeBox
                heading={<Fragment><h3><Link to={routes.docusignSendEnvelope.path}>{routes.docusignSendEnvelope.label}</Link></h3></Fragment>}
                description={<Fragment>Send a Docusign envelope, click <Link to={routes.docusignSendEnvelope.path}>here</Link></Fragment>}
                icon={routes.docusignSendEnvelope.icon}
              />
              <HomeBox
                heading={<Fragment><h3><Link to={routes.docusignConfig.path}>{routes.docusignConfig.label}</Link></h3></Fragment>}
                description={<Fragment>Configure Docusign, click <Link to={routes.docusignConfig.path}>here</Link></Fragment>}
                icon={routes.docusignConfig.icon}
              />
            </Fragment>
          )}
        </Fragment>
      )}
      <hr />
      <h3>Employee Documents</h3>
      <ul>
        <li><a target="_blank" rel="noreferrer" href="https://db.mossmont.com.au/tfn_form-mossmont_nurseries.pdf">TFN Form (link)</a> - Print, fill out and sign this form, then give to Mossmont staff.</li>
        <li><a target="_blank" rel="noreferrer" href="https://db.mossmont.com.au/piece_work_agreement-mossmont_nurseries.pdf">Piece Work Agreement (link)</a> - Print, fill out and sign this form, then give to Mossmont staff.</li>
        <li><a target="_blank" rel="noreferrer" href="https://db.mossmont.com.au/induction_booklet-mossmont_nurseries.pdf">Worker Induction Booklet (link)</a> - Print and read this.</li>
      </ul>
    </Page>
  )
})
