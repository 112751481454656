import { useContext, useState, SyntheticEvent } from 'react'
import { observer } from 'mobx-react'
import { Redirect } from 'react-router-dom'

import { routes } from '../routes'
import { RootStoreContext } from '../store/Store'
import Page from '../components/Page'

export default observer(({
  ...props
}) => {

  const store = useContext(RootStoreContext)
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  if (store.sessionStore.checkedSessionOnLoad && store.sessionStore.isAuthenticated) {
    return (
      <Redirect to={routes.home.path} />
    )
  }

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value)
  }
  
  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value)
  }

  const handleLogin = (event: SyntheticEvent) => {
    event.preventDefault()
    store.apiStore.login({
      email,
      password,
    }).catch((err) => {
      store.apiStore.handleFormError(err, "There was an error logging in.")
    })
  }

  return (
    <Page
      title={ routes.login.label }
      iconClasses={ routes.login.icon }
      {...props}
    >
      <form method="POST" onSubmit={handleLogin}>
        <div className="form-group required">
          <label htmlFor="id_email" className="control-label">Email</label>
          <input id="id_email" name="email" type="email" className="form-control" required onChange={handleEmailChange} />
        </div>
        <div className="form-group required">
          <label htmlFor="id_password" className="control-label">Password</label>
          <input id="id_password" name="password" type="password" className="form-control" required onChange={handlePasswordChange} />
        </div>
        <input type="submit" className='btn btn-primary' value="Login" />
      </form>
    </Page>
  )
})
