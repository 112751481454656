import React, {useContext, useState, useEffect, SyntheticEvent, Fragment} from 'react'
import { observer } from 'mobx-react'
import { Redirect } from 'react-router-dom'
import omit from 'lodash/omit'

import { RootStoreContext } from '../store/Store'
import { routes } from '../routes'
import Page from '../components/Page'
import Lookup from '../components/Lookup'
import BudCountSearchTable from '../components/BudCountSearchTable'
import './BudCountSearch.css'

export interface IState {
  [key: string]: any,
}

export default observer(
  ({ ...props }) => {

    const store = useContext(RootStoreContext)

    let currentYear = new Date().getFullYear()
    if (currentYear < 2022) {
      currentYear = 2022
    }
    const [state, setState] = useState<IState>({
      loaded: false,
      redirectToHome: false,
      year: undefined,
      variety: undefined,
      data: [],
    })

    const [budLocations, setBudLocations] = useState([])

    const [countsUpdated, setCountsUpdated] = useState(false)

    useEffect(() => {
      if (store.sessionStore.checkedSessionOnLoad) {
        if (!store.sessionStore.isAuthenticated) {
          setState({
            ...state,
            redirectToHome: true,
            loaded: true,
          })
        } else {
          if (!state.loaded) {
            setState({
              ...state,
              loaded: true,
            })
          }
        }
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.sessionStore.checkedSessionOnLoad, store.sessionStore.isAuthenticated, state.loaded])

    useEffect(() => {
      if (state.loaded) {
        store.apiStore.getLookup('bud_location', {}).then((data) => {
          setBudLocations(data)
        })
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.loaded])

    if ( state.redirectToHome ) {
      return <Redirect to={routes.home.path} />
    }

    const handleYearChange = (selected: {label: string, value: string}) => {
      setState({
        ...state,
        year: selected,
      })
    }

    const handleVarietyChange = (selected: {label: string, value: string}) => {
      setState({
        ...state,
        variety: selected,
      })
    }

    const handleBudCountSearch = (event: SyntheticEvent) => {
      event.preventDefault();
      store.apiStore.budCountSearch(omit({
        delivery_year_id: state.year?.value,
        variety_id: state.variety?.value,
      }, ['loaded', 'redirectToHome'])).then((data) => {
        setState({
          ...state,
          data,
        })
        setCountsUpdated(false)
      }).catch((err) => {
        store.apiStore.handleFormError(err, "There was an error searching bud counts.")
      })
    }

    return (
      <Page
        title={routes.budCountSearch.label}
        iconClasses={routes.budCountSearch.icon}
        {...props}
      >
        <form method="POST" onSubmit={handleBudCountSearch}>
          <div className="form-group required">
            <Lookup
              lookup='delivery_year'
              label="Year"
              id="id_year"
              name="year"
              defaultValue={String(currentYear)}
              value={state.year}
              onChange={handleYearChange}
            />
          </div>
          <div className="form-group required">
            <Lookup
              lookup='variety'
              label="Variety"
              id="id_variety"
              name="variety"
              value={state.variety}
              onChange={handleVarietyChange}
            />
          </div>
          <input type="submit" name="submit" value="Search" className="btn btn-primary" />
          {!!state.data && Array.isArray(state.data) && state.data.length > 0 && (
            <Fragment>
              <hr />
              <BudCountSearchTable data={state.data} year={state.year} setCountsUpdated={setCountsUpdated} countsUpdated={countsUpdated} budLocations={budLocations} />
            </Fragment>
          )}
        </form>
      </Page>
    )
  })
