import React, { Fragment, useState, useContext, useEffect, SyntheticEvent, ChangeEvent, KeyboardEvent } from 'react'
import { Link } from 'react-router-dom'
import { useTable } from 'react-table'
import BTable from 'react-bootstrap/Table'
import queryString from 'query-string'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'

import { routes } from '../routes'
import { DEFAULT_PAGE_SIZE } from '../constants'
import { RootStoreContext } from '../store/Store'
import { ITable } from '../tables/core'
import './SearchTable.css'

interface IProps {
    table: ITable,
    adjacents?: number,
    showSearch?: boolean,
}

interface ITableState {
    loaded: boolean,
    data: object[],
    count: number,
    prev_url?: string,
    next_url?: string,
    current_page: number,
    page_size: number,
    ordering?: string | string[] | null,
    search?: string | string[] | null,
}

const SearchTable = ({
    table,
    adjacents = 2,
    showSearch = true,
    ...props
}: IProps) => {

    /* eslint-disable jsx-a11y/anchor-is-valid */

    const parsedQs = queryString.parse(window.location.search)

    const store = useContext(RootStoreContext)

    const emptyFormState = {
        loaded: false,
        data: [],
        count: 0,
        prev_url: undefined,
        next_url: undefined,
        current_page: Number(get(parsedQs, 'page', 1)),
        page_size: Number(get(parsedQs, 'page_size', window.localStorage.getItem('page_size') || String(DEFAULT_PAGE_SIZE))),
        ordering: get(parsedQs, 'ordering'),
        search: showSearch ? get(parsedQs, 'search') : undefined,
    }
    const [tableState, setTableState] = useState<ITableState>(emptyFormState)

    interface ISetStateIntoStorageAndWindowSearch {
        page_size: string,
        page: string,
        ordering?: string | string[] | null,
        search?: string | string[] | null,
    }
    const setStateIntoStorageAndWindowSearch = ({ page_size, page, ordering, search }: ISetStateIntoStorageAndWindowSearch) => {
        const currentSearchParams = queryString.parse(window.location.search)
        const searchTableParams = { page_size, page, ordering, search }
        if (!showSearch) {
            delete searchTableParams['search']
        }
        if (!isEqual(currentSearchParams, searchTableParams)) {
            window.history.replaceState( {}, document.title, `${window.location.origin}${window.location.pathname}?${queryString.stringify(searchTableParams)}`)
        }
        // set page size to localStorage
        window.localStorage.setItem('page_size', String(page_size))
    }

    useEffect(() => {
        // replace querystring in window
        const searchTableParams = { page_size: String(tableState.page_size), page: String(tableState.current_page), ordering: tableState.ordering, search: tableState.search }
        setStateIntoStorageAndWindowSearch(searchTableParams)
        store.apiStore.searchTable(table, searchTableParams).then((data) => {
            setTableState({
                ...tableState,
                loaded: true,
                data: data.results,
                count: data.count,
                prev_url: data.previous || undefined,
                next_url: data.next || undefined,
            })
        }).catch((err) => {
            setTableState({
                ...emptyFormState,
                current_page: 1,
            })
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tableState.page_size, tableState.current_page, tableState.ordering, tableState.search])
    
    const tableDataStore = React.useMemo(
        () => tableState.data,
        [tableState.data]
    )

    const columns = React.useMemo(
        () => table.columns,
        [table.columns]
    )

    const { getTableProps, headerGroups, rows, prepareRow } = useTable({
        columns,
        data: tableDataStore || [],
        initialState: {
            hiddenColumns: table.hiddenColumns,
        },
    })

    if (!tableState.loaded) {
        return (
            <div className="loading-spinner">
                <div className="spin"></div>
            </div>
        )
    }

    const handlePageButtonClick = (event: SyntheticEvent<EventTarget>) => {
        if (!(event.target instanceof HTMLButtonElement)) {
            return
        }
        setStateIntoStorageAndWindowSearch({ page: String(event.target.dataset.pageNumber), page_size: String(tableState.page_size), ordering: tableState.ordering, search: tableState.search })
        const current_page = Number(event.target.dataset.pageNumber)
        setTableState({
            ...tableState,
            current_page,
        })
    }

    const buildFirstButton = () => {
        let button = null
        if (tableState.current_page > 1) {
            button = (
                <button type="button" className="btn btn-secondary" data-page-number={1} onClick={handlePageButtonClick}>1</button>
            )
        }
        return button
    }

    const buildLastButton = () => {
        let button = null
        if (tableState.current_page < Math.ceil(tableState.count / tableState.page_size)) {
            button = (
                <button type="button" className="btn btn-secondary" data-page-number={Math.ceil(tableState.count / tableState.page_size)} onClick={handlePageButtonClick}>{Math.ceil(tableState.count / tableState.page_size)}</button>
            )
        }
        return button
    }

    const buildPreviousAdjacentButtons = () => {
        let buttons = []
        for (var i=adjacents; i>=1; i--) {
            if (tableState.current_page - i >= 2) {
                buttons.push(
                    <button type="button" key={tableState.current_page - i} className="btn btn-secondary" data-page-number={tableState.current_page - i} onClick={handlePageButtonClick}>{tableState.current_page - i}</button>
                )
            }
        }
        return buttons
    }

    const buildNextAdjacentButtons = () => {
        let buttons = []
        for (var i=1; i<=adjacents; i++) {
            if (tableState.current_page + i <= Math.ceil(tableState.count / tableState.page_size) - 1) {
                buttons.push(
                    <button key={tableState.current_page + i} className="btn btn-secondary" data-page-number={tableState.current_page + i} onClick={handlePageButtonClick}>{tableState.current_page + i}</button>
                )
            }
        }
        return buttons
    }

    const handlePerPageChange = (event: ChangeEvent<HTMLSelectElement>) => {
        setStateIntoStorageAndWindowSearch({ page: String(tableState.current_page), page_size: event.target.value, ordering: tableState.ordering, search: tableState.search })
        setTableState({
            ...tableState,
            page_size: Number(event.target.value),
        })
    }

    const buildPagination = () => {
        return (
            <div className="search-table__pagination-container">
                <div className="btn-toolbar" role="toolbar" aria-label="Pagination toolbar">
                    <div className="btn-group mr-2" role="group" aria-label="Pagination button group">
                        {buildFirstButton()}
                        {buildPreviousAdjacentButtons()}
                        <button type="button" className="btn btn-primary">{tableState.current_page}</button>
                        {buildNextAdjacentButtons()}
                        {buildLastButton()}
                    </div>
                </div>
                <div className="search-table__pagination-per-page-container">
                    <label htmlFor="records_per_page">Records per Page</label>
                    <select id="records_per_page" value={tableState.page_size} className="form-control" onChange={handlePerPageChange}>
                        <option value="15">15</option>
                        <option value="30">30</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                </div>
            </div>
        )
    }

    const handleHeadingClick = (event: SyntheticEvent<EventTarget>) => {
        let columnId = null
        if (event.target instanceof HTMLAnchorElement) {
            columnId = event.target.dataset.columnOrdering || event.target.dataset.columnId
        // else it's the <i> element clicked
        } else if (event.target instanceof HTMLElement) {
            columnId = event?.target?.parentElement?.dataset.columnOrdering || event?.target?.parentElement?.dataset.columnId
        }
        event.preventDefault()
        if ( tableState.ordering === columnId ) {
            setStateIntoStorageAndWindowSearch({page: String(tableState.current_page), page_size: String(tableState.page_size), ordering: `-${columnId}`, search: tableState.search})
            setTableState({
                ...tableState,
                ordering: `-${columnId}`
            })
        } else { 
            setStateIntoStorageAndWindowSearch({page: String(tableState.current_page), page_size: String(tableState.page_size), ordering: columnId, search: tableState.search})
            setTableState({
                ...tableState,
                ordering: columnId
            })
        }
    }

    const handleCancelOrdering = (event: SyntheticEvent<EventTarget>) => {
        setStateIntoStorageAndWindowSearch({page: String(tableState.current_page), page_size: String(tableState.page_size), ordering: undefined, search: tableState.search})
        setTableState({
            ...tableState,
            ordering: undefined,
        })
    }

    const handleSearchClick = (event: SyntheticEvent<EventTarget>) => {
        const search = (document.getElementById('id_search') as HTMLInputElement).value || ''
        setStateIntoStorageAndWindowSearch({page: String(tableState.current_page), page_size: String(tableState.page_size), ordering: tableState.ordering, search })
        setTableState({
            ...tableState,
            search,
        })
    }

    const handleSearchKeyPress = (event: KeyboardEvent<EventTarget>) => {
        const keyCode = event.key
        if (keyCode === 'Enter' || keyCode === 'NumpadEnter'){
            (document.getElementById('id_search_button') as HTMLInputElement).click()
        }
    }

    const processToUrl = (table: ITable, cell: any) => {
        const to_url_params = table.to_url_params || {}
        let to_url = get(routes, `${table.to_url}`, "") as string
        Object.keys(to_url_params).forEach(key=>{
            to_url = to_url.replace(key, get(cell, `row.values.${to_url_params[key]}`))
        })
        return to_url
    }

    return (
        <Fragment>
            <div className="search-table__container">
                <div className="search-table__search-container">
                    <div className="input-group">
                        <input id="id_search" name="search" className="form-control" defaultValue={tableState.search || ""} onKeyPress={handleSearchKeyPress} />
                        <div className="input-group-append">
                            <button id="id_search_button" type="button" className="btn btn-secondary" onClick={handleSearchClick}><i className="fas fa-search"></i></button>
                        </div>
                    </div>
                </div>
                <BTable striped bordered hover responsive size="sm" className="search-table__bootstrap-table" {...getTableProps()}>
                    <thead>
                        {headerGroups.map((headerGroup: any) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column: any) => (
                                    <th {...column.getHeaderProps()}>
                                        <a href="#" data-column-id={column.id} data-column-ordering={column.ordering} onClick={handleHeadingClick}>
                                            {column.render('Header')} <i className={`fas ${(tableState.ordering === column.id || (tableState.ordering === column.ordering && !!column.ordering)) ? 'fa-sort-up' : ((tableState.ordering === `-${column.id}` || (tableState.ordering === `-${column.ordering}` && !!column.ordering)) ? 'fa-sort-down': 'fa-sort')}`}></i>
                                        </a>
                                        {(tableState.ordering === `-${column.id}` || tableState.ordering === column.id || (tableState.ordering === `-${column.ordering}` && !!column.ordering) || (tableState.ordering === column.ordering && !!column.ordering)) && (
                                            <i className="fas fa-times search-table__ordering-cross" onClick={handleCancelOrdering}></i>
                                        )}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody>
                        {!rows.length && (
                            <span className="search-table__no-records">No records</span>
                        )}
                        {!!rows.length && rows.map((row: any, i: any) => {
                            prepareRow(row)
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell: any) => {
                                        return (
                                            <td {...cell.getCellProps()}>
                                                {!!table.to_url && (
                                                  <Link to={processToUrl(table, cell)}>{cell.value === true ? <i className="fas fa-check"></i> : (cell.value === false ? <i className="fas fa-times"></i> : cell.render('Cell'))}</Link>
                                                )}
                                                {!table.to_url && (
                                                  <div>{cell.value === true ? <i className="fas fa-check"></i> : (cell.value === false ? <i className="fas fa-times"></i> : cell.render('Cell'))}</div>
                                                )}
                                            </td>
                                        )
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
                </BTable>
                {!!rows.length && buildPagination()}
            </div>
        </Fragment>
    )
}

export default SearchTable
