import './TopNav.css'

export default function TopNav ({
    ...props
}) {
  return (
    <nav className='main-header navbar navbar-expand navbar-white navbar-light'>
      <ul className="navbar-nav">
        <li className="nav-item">
          {// eslint-disable-next-line jsx-a11y/anchor-is-valid
          }<a className="nav-link" data-widget="pushmenu" href="#"><i className="fa fa-bars"></i></a>
        </li>
      </ul>
    </nav>
  )
}
