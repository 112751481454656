import React, {Fragment, useContext} from 'react'
import Select from 'react-select'

import { RootStoreContext } from '../store/Store'
import './BudCountSearchTable.css'

interface IBudCountSearchTableProps {
  data: any[]
  year: {value: string, label: string}
  setCountsUpdated: Function
  countsUpdated: boolean
  budLocations: any[]
}

interface budCountRecordData {
  product_id?: string,
  bud_count_record_id?: string,
  count: number,
  bundle_no: number,
  bud_location_id?: string,
}

interface ICountState {
  [key: string]: budCountRecordData
}

export default function BudCountSearchTable({
  data,
  year,
  setCountsUpdated,
  countsUpdated,
  budLocations
}: IBudCountSearchTableProps) {
  const [countState, setCountState] = React.useState<ICountState>({})
  React.useEffect(() => {
    const newCountState: ICountState = {}
    data.forEach((product) => {
      newCountState[product.product_id] = {
        bud_count_record_id: product.bud_count_record_id,
        count: 0,
        bundle_no: 0,
        bud_location_id: undefined,
      }
    })
    setCountState(newCountState)
  }, [data])

  const handleAddCount = (event: React.FormEvent<HTMLInputElement>) => {
    const product_id = String(event.currentTarget.dataset.productId)
    const count = Number(event.currentTarget.value)
    setCountState({
      ...countState,
      [product_id]: {
        ...countState[product_id],
        count: count
      }
    })
  }

  const handleAddBundle = (event: React.FormEvent<HTMLInputElement>) => {
    const product_id = String(event.currentTarget.dataset.productId)
    const bundle_no = Number(event.currentTarget.value)
    setCountState({
      ...countState,
      [product_id]: {
        ...countState[product_id],
        bundle_no: bundle_no
      }
    })
  }

  const handleChangeLocation = (product_id: string) => (value?: {value: string, label: string}) => {
    setCountState({
      ...countState,
      [product_id]: {
        ...countState[product_id],
        bud_location_id: value?.value,
      }
    })
  }

  const store = useContext(RootStoreContext)

  const handleUpdateBudCounts = (event: React.FormEvent<HTMLButtonElement>) => {
    event.preventDefault()
    const productUpdateRecords: budCountRecordData[] = []
    let errorFound = false
    Object.keys(countState).forEach((product_id) => {
      const count = countState[product_id].count
      const bundle_no = countState[product_id].bundle_no
      const bud_location_id = countState[product_id].bud_location_id
      const bud_count_record_id = countState[product_id].bud_count_record_id
      const countIsEntered = count !== 0
      const bundleNoIsEntered = bundle_no !== 0
      const budLocationIdIsEntered = !!bud_location_id
      if (countIsEntered || bundleNoIsEntered || budLocationIdIsEntered) {
        if (!budLocationIdIsEntered && !bud_count_record_id) {
          alert('For new counts, you must enter a bud location.')
          errorFound = true
          return
        }
        productUpdateRecords.push({
          product_id: product_id,
          bud_count_record_id: bud_count_record_id,
          count: count,
          bundle_no: bundle_no,
          bud_location_id: bud_location_id,
        })
      }
    })
    if (errorFound) return
    if (productUpdateRecords.length === 0) {
      alert('No counts entered.')
      return
    }
    store.apiStore.budCountUpdate({
      delivery_year_id: year.value,
      products: productUpdateRecords,
    }).then((data) => {
      if (data.success) {
        setCountsUpdated(true)
      } else {
        alert('There was an issue updating bud counts on the server.')
      }
    }).catch((e) => {
      console.log(e)
    })
  }

  return (
    <Fragment>
      {countsUpdated && (
        <div className="alert alert-success">
          <h5><i className="icon fas fa-check" /> Success</h5>
          Your bud counts were saved
        </div>
      )}
      {!countsUpdated && (
        <Fragment>
          <button type="button" className="btn btn-primary update-bud-counts-btn" onClick={handleUpdateBudCounts}><i className="fas fa-seedling" /> Update Bud Counts</button>
          <h6 className="counts-heading">Bud Counts</h6>
          {!!data && Array.isArray(data) && data.map((product) => {
            return (
              <div key={product.product_id} className="row">
                <div className="col-12 card">
                  <div className="card-body">
                    <div className="row card-row">
                      <div className="heading">Variety</div>
                      <div className="value-cell">{product.variety.var_description}</div>
                    </div>
                    <div className="row card-row">
                      <div className="heading">Grow Type</div>
                      <div className="value-cell">{product.grow_type.gro_description}</div>
                    </div>
                    <div className="row card-row">
                      <div className="heading">Rootstock</div>
                      <div className="value-cell">{product.rootstock.roo_description}</div>
                    </div>
                    <div className="row card-row">
                      <div className="heading">Count</div>
                      <div className="value-cell">{product.current_count}</div>
                    </div>
                    <div className="row card-row">
                      <div className="heading">Add Count</div>
                      <div className="value-cell">
                        <input className="add-count-input" type="number" defaultValue={0} data-product-id={product.product_id} onChange={handleAddCount} />
                      </div>
                    </div>
                    <div className="row card-row">
                      <div className="heading">Bundle No</div>
                      <div className="value-cell">{product.current_bundle}</div>
                    </div>
                    <div className="row card-row">
                      <div className="heading">Add Bundle</div>
                      <div className="value-cell">
                        <input className="add-bundle-input" type="number" defaultValue={0} data-product-id={product.product_id} onChange={handleAddBundle} />
                      </div>
                    </div>
                    <div className="row card-row">
                      <div className="heading">Location</div>
                      <div className="value-cell">{product.current_location?.blo_description || '-'}</div>
                    </div>
                    <div className="row card-row">
                      <div className="heading">Change Location</div>
                      <div className="value-cell">
                        <Select
                          isClearable={true}
                          onChange={handleChangeLocation(product.product_id)}
                          options={budLocations}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
          <button type="button" className="btn btn-primary update-bud-counts-btn" onClick={handleUpdateBudCounts}><i className="fas fa-seedling" /> Update Bud Counts</button>
        </Fragment>
      )}
    </Fragment>
  )
}