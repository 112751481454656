import Home from './views/Home'
import Login from './views/Login'
import Logout from './views/Logout'
import Register from './views/Register'
import Details from './views/Details'
import BudCountSearch from './views/BudCountSearch'
import BudCountHistory from './views/BudCountHistory'
import CreateUser from './views/CreateUser'
import Users from './views/Users'
import EditUser from './views/EditUser'
import DocusignConfig from './views/DocusignConfig'
import DocusignSuccess from './views/DocusignSuccess'
import DocusignSendEnvelope from './views/SendDocusignEnvelope'

export interface IRoute {
  path: string,
  component: any,
  label: string,
  icon: string,
}

export interface IRoutes {
  [key: string]: IRoute,
}

export const routes : IRoutes = {
  home: {
    path: "/",
    component: Home,
    label: "Home",
    icon: "fas fa-home",
  },
  login: {
    path: "/login",
    component: Login,
    label: "Login",
    icon: "fas fa-sign-in-alt",
  },
  logout: {
    path: "/logout",
    component: Logout,
    label: "Logout",
    icon: "fas fa-sign-out-alt",
  },
  register: {
    path: "/register",
    component: Register,
    label: "Register",
    icon: "fas fa-user-plus",
  },
  details: {
    path: "/details",
    component: Details,
    label: "Employee Details",
    icon: "fas fa-address-card",
  },
  budCountSearch: {
    path: "/bud-counts",
    component: BudCountSearch,
    label: "Bud Counts",
    icon: "fas fa-seedling",
  },
  budCountHistory: {
    path: "/bud-count-history",
    component: BudCountHistory,
    label: "Bud Count History",
    icon: "fas fa-user-clock",
  },
  createUser: {
    path: "/create-user",
    component: CreateUser,
    label: "Create User",
    icon: "fas fa-user-plus",
  },
  users: {
    path: "/users",
    component: Users,
    label: "Users",
    icon: "fas fa-users",
  },
  editUser: {
    path: "/users/:id",
    component: EditUser,
    label: "Edit User",
    icon: "fas fa-user",
  },
  docusignConfig: {
    path: "/docusign/config",
    component: DocusignConfig,
    label: "Docusign Config",
    icon: "fas fa-cog",
  },
  docusignSuccess: {
    path: "/docusign/success",
    component: DocusignSuccess,
    label: "Docusign Config",
    icon: "fas fa-cog",
  },
  docusignSendEnvelope: {
    path: "/docusign/send_envelope",
    component: DocusignSendEnvelope,
    label: "Send Docusign Manually",
    icon: "fas fa-envelope",
  }
}
