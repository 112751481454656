import React, { useContext, SyntheticEvent, useState, Fragment, useEffect } from 'react'
import { observer } from 'mobx-react'
import { Redirect } from 'react-router-dom'

import { REGULATED_SUPER_TYPE } from '../constants'
import { RootStoreContext } from '../store/Store'
import { routes } from '../routes'
import Page from '../components/Page'
import DateWidget from '../components/DateWidget'
import './Details.css'

export const protectedDetailsStateAttrs = [
  "completed",
  "loaded",
  "email",
  "confirm_password",
]

export interface IState {
  [key: string]: any,
}

export default observer(
  ({ ...props }) => {

    const store = useContext(RootStoreContext)

    const [formState, setFormState] = useState<IState>({
      loaded: false,
      completed: false,
      redirectToHome: false,
      first_name: "",
      middle_names: "",
      last_name: "",
      date_of_birth: undefined,
      address_line_1: "",
      address_line_2: "",
      suburb: "",
      post_code: "",
      country: "",
      passport_number: "",
      passport_nationality: "",
      madec_number: "",
      start_date: undefined,
      title: "",
      gender: "",
      phone: "",
      mobile: "",
      tax_file_number: "",
      super_specified: false,
      super_type: "",
      super_usi: "",
      super_employee_number: "",
      bank_account_name: "",
      bank_bsb_number: "",
      bank_account_number: "",
      date_joined: "",
      date_last_modified: "",
      password: "",
      confirm_password: "",
    })

    useEffect(() => {
      if (store.sessionStore.checkedSessionOnLoad) {
        if (!store.sessionStore.isAuthenticated) {
          setFormState({
            ...formState,
            redirectToHome: true,
            loaded: true,
          })
        } else {
          if (!formState.loaded) {
            setFormState({
              ...formState,
              ...store.sessionStore,
              loaded: true,
            })
          }
        }
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.sessionStore.checkedSessionOnLoad, store.sessionStore.isAuthenticated, formState.loaded])

    if ( formState.redirectToHome ) {
      return <Redirect to={routes.home.path} />
    }

    const handleTextFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setFormState({
        ...formState,
        [event.target.name]: event.target.value,
      })
    }

    const handleSelectFieldChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
      setFormState({
        ...formState,
        [event.target.name]: event.target.value,
      })
    }

    const handleDateOfBirthFieldChange = (date: Date | undefined) => {
      setFormState({
        ...formState,
        'date_of_birth': date,
      })
    }

    const handleStartDateFieldChange = (date: Date | undefined) => {
      setFormState({
        ...formState,
        'start_date': date,
      })
    }

    const handleSuperSpecifiedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.checked
      let extra_data = {}
      if (value) {
        extra_data = {
          super_type: REGULATED_SUPER_TYPE
        }
      } else {
        extra_data = {
          super_type: ""
        }
      }
      setFormState({
        ...formState,
        ...extra_data,
        'super_specified': value,
      })
    }

    const handleDetails = (event: SyntheticEvent) => {
      event.preventDefault();
      if (formState.password !== formState.confirm_password) {
        store.apiStore.setError("Password does not match Confirm Password.")
        store.apiStore.requestOver()
        window.scroll(0, 0)
        return false
      }
      store.apiStore.details({
        ...formState,
        date_of_birth: formState.date_of_birth,
        start_date: formState.start_date,
      }).then((data) => {
        store.sessionStore.setUserData(data)
        setFormState({
          ...formState,
          completed: true,
        })
      }).catch((err) => {
        store.apiStore.handleFormError(err, "There was an error saving your details.")
      })
    }

    return (
      <Page
        title={routes.details.label}
        iconClasses={routes.details.icon}
        {...props}
      >
        {!formState.completed && (
          <form method="POST" onSubmit={handleDetails}>
            <div className="form-group required">
              <label htmlFor="id_first_name" className="control-label">First Name</label>
              <input id="id_first_name" name="first_name" value={formState.first_name} className="form-control" required onChange={handleTextFieldChange} />
            </div>
            <div className="form-group">
              <label htmlFor="id_middle_names" className="control-label">Middle Names</label>
              <input id="id_middle_names" name="middle_names" value={formState.middle_names} className="form-control" onChange={handleTextFieldChange} />
            </div>
            <div className="form-group required">
              <label htmlFor="id_last_name" className="control-label">Last Name</label>
              <input id="id_last_name" name="last_name" value={formState.last_name} className="form-control" required onChange={handleTextFieldChange} />
            </div>
            <div className="form-group required">
              <label htmlFor="id_date_of_birth" className="control-label">Date Of Birth</label>
              <DateWidget
                value={formState.date_of_birth}
                onChange={handleDateOfBirthFieldChange}
                id="id_date_of_birth"
                name="date_of_birth"
                required
              />
            </div>
            <div className="form-group required">
              <label htmlFor="id_address_line_1" className="control-label">Address Line 1</label>
              <input id="id_address_line_1" name="address_line_1" value={formState.address_line_1} className="form-control" required onChange={handleTextFieldChange} />
            </div>
            <div className="form-group">
              <label htmlFor="id_address_line_2" className="control-label">Address Line 2</label>
              <input id="id_address_line_2" name="address_line_2" value={formState.address_line_2} className="form-control" onChange={handleTextFieldChange} />
            </div>
            <div className="form-group required">
              <label htmlFor="id_suburb" className="control-label">Suburb / Town</label>
              <input id="id_suburb" name="suburb" value={formState.suburb} className="form-control" required onChange={handleTextFieldChange} />
            </div>
            <div className="form-group required">
              <label htmlFor="id_post_code" className="control-label">Postcode</label>
              <input id="id_post_code" name="post_code" value={formState.post_code} className="form-control" required onChange={handleTextFieldChange} />
            </div>
            <div className="form-group required">
              <label htmlFor="id_country" className="control-label">Country</label>
              <select id="id_country" name="country" value={formState.country} className="form-control" required onChange={handleSelectFieldChange}>
                <option value=""></option>
                <option value="AUSTRALIA">AUSTRALIA</option>
              </select>
            </div>
            <div className="form-group required">
              <label htmlFor="id_state" className="control-label">State</label>
              <select id="id_state" name="state" value={formState.state} className="form-control" required onChange={handleSelectFieldChange}>
                <option value=""></option>
                <option value="ACT">ACT</option>
                <option value="NSW">NSW</option>
                <option value="NT">NT</option>
                <option value="QLD">QLD</option>
                <option value="SA">SA</option>
                <option value="TAS">TAS</option>
                <option value="VIC">VIC</option>
                <option value="WA">WA</option>
              </select>
            </div>
            <div className="form-group required">
              <label htmlFor="id_passport_number" className="control-label">Passport Number</label>
              <input id="id_passport_number" name="passport_number" value={formState.passport_number} className="form-control" required onChange={handleTextFieldChange} />
            </div>
            <div className="form-group required">
              <label htmlFor="id_passport_nationality" className="control-label">Passport Nationality</label>
              <input id="id_passport_nationality" name="passport_nationality" value={formState.passport_nationality} className="form-control" required onChange={handleTextFieldChange} />
            </div>
            <div className="form-group required">
              <label htmlFor="id_madec_number" className="control-label">Madec Number</label>
              <input id="id_madec_number" name="madec_number" value={formState.madec_number} className="form-control" required onChange={handleTextFieldChange} />
            </div>
            <div className="form-group required">
              <label htmlFor="id_start_date" className="control-label">Start Date</label>
              <DateWidget
                value={formState.start_date}
                onChange={handleStartDateFieldChange}
                id="id_start_date"
                name="start_date"
                required
              />
            </div>
            <div className="form-group required">
              <label htmlFor="id_title" className="control-label">Title</label>
              <select id="id_title" name="title" value={formState.title} className="form-control" required onChange={handleSelectFieldChange}>
                <option value=""></option>
                <option value="Mr">Mr</option>
                <option value="Mrs">Mrs</option>
                <option value="Miss">Miss</option>
                <option value="Ms">Ms</option>
                <option value="Dr">Dr</option>
              </select>
            </div>
            <div className="form-group required">
              <label htmlFor="id_gender" className="control-label">Gender</label>
              <select id="id_gender" name="gender" value={formState.gender} className="form-control" required onChange={handleSelectFieldChange}>
                <option value=""></option>
                <option value="M">Male</option>
                <option value="F">Female</option>
                <option value="U">Unspecified</option>
              </select>
            </div>
            <div className="form-group required">
              <label htmlFor="id_phone" className="control-label">Phone</label>
              <input id="id_phone" name="phone" value={formState.phone} className="form-control" required onChange={handleTextFieldChange} />
            </div>
            <div className="form-group required">
              <label htmlFor="id_mobile" className="control-label">Mobile</label>
              <input id="id_mobile" name="mobile" value={formState.mobile} className="form-control" required onChange={handleTextFieldChange} />
            </div>
            <div className="form-group required">
              <label htmlFor="id_tax_file_number" className="control-label">Tax File Number</label>
              <input id="id_tax_file_number" name="tax_file_number" value={formState.tax_file_number} className="form-control" required onChange={handleTextFieldChange} />
            </div>
            <h4 className="form-sub-heading">Super Details</h4>
            <div className="form-group form-check">
              <input id="id_super_specified" type="checkbox" name="super_specified" checked={formState.super_specified} className="form-check-input" onChange={handleSuperSpecifiedChange} />
              <label htmlFor="id_super_specified" className="form-check-label">Super Specified</label>
            </div>
            {!!formState.super_specified && (
              <Fragment>
                <div className="form-group required">
                  <label htmlFor="id_super_type" className="control-label">Super Type</label>
                  <select id="id_super_type" name="super_type" value={formState.super_type} className="form-control" required onChange={handleSelectFieldChange}>
                    <option value="REGULATED">Regulated</option>
                  </select>
                </div>
                <div className="form-group required">
                  <label htmlFor="id_super_usi" className="control-label">Super USI</label>
                  <input id="id_super_usi" name="super_usi" value={formState.super_usi} className="form-control" required onChange={handleTextFieldChange} />
                </div>
                <div className="form-group required">
                  <label htmlFor="id_super_employee_number" className="control-label">Super Employee Number</label>
                  <input id="id_super_employee_number" name="super_employee_number" value={formState.super_employee_number} className="form-control" required onChange={handleTextFieldChange} />
                </div>
              </Fragment>
            )}
            {!formState.super_specified && (
              <Fragment>
                <div className="alert alert-info" role="alert">
                  AustralianSuper will be used when no Super Fund is specified. If you have a Self-managed Super Fund, email admin@mossmont.com.au stating you have a Self-Managed Super Fund along with the relevant documents.
                </div>
              </Fragment>
            )}
            <h4 className="form-sub-heading">Bank Details</h4>
            <div className="form-group required">
              <label htmlFor="id_bank_account_name" className="control-label">Bank Account Name</label>
              <input id="id_bank_account_name" name="bank_account_name" value={formState.bank_account_name} className="form-control" required onChange={handleTextFieldChange} />
            </div>
            <div className="form-group required">
              <label htmlFor="id_bank_bsb_number" className="control-label">Bank BSB Number</label>
              <input id="id_bank_bsb_number" name="bank_bsb_number" value={formState.bank_bsb_number} className="form-control" required onChange={handleTextFieldChange} />
            </div>
            <div className="form-group required">
              <label htmlFor="id_bank_account_number" className="control-label">Bank Account Number</label>
              <input id="id_bank_account_number" name="bank_account_number" value={formState.bank_account_number} className="form-control" required onChange={handleTextFieldChange} />
            </div>
            <input type="submit" className='btn btn-primary' value="Update" />
            <h4 className="form-sub-heading">Update Password</h4>
            <div className="form-group">
              <label htmlFor="id_password" className="control-label">Password</label>
              <input type="password" id="id_password" name="password" value={formState.password} className="form-control" onChange={handleTextFieldChange} />
            </div>
            <div className="form-group">
              <label htmlFor="id_confirm_password" className="control-label">Confirm Password</label>
              <input type="password" id="id_confirm_password" name="confirm_password" value={formState.confirm_password} className="form-control" onChange={handleTextFieldChange} />
            </div>
            <input type="submit" className='btn btn-primary' value="Update" />
          </form>
        )}
        {!!formState.completed && (
          <div className="alert alert-success" role="alert">
            <h4 className="alert-heading"><i className="fas fa-check"></i> Success!</h4>
            <p>Thanks for updating your details!</p>
            <p>Mossmont staff will process your details and let you know if any more information is required.</p>
          </div>
        )}
      </Page>
    )
  })
