import { useContext, useState, SyntheticEvent, useEffect } from 'react'
import { observer } from 'mobx-react'
import { Redirect, Link, useParams } from 'react-router-dom'

import { routes } from '../routes'
import { RootStoreContext } from '../store/Store'
import Page from '../components/Page'

export interface IState {
  [key: string]: any,
}

export default observer(({
  ...props
}) => {

  /* eslint-disable jsx-a11y/anchor-is-valid */

  const { id: userId } = useParams()

  const store = useContext(RootStoreContext)

  const emptyFormState = {
    loaded: false,
    completed: false,
    redirectToHome: false,
    email: "",
    password: "",
    confirm_password: "",
    first_name: "",
    middle_names: "",
    last_name: "",
    is_active: false,
    is_staff: false,
    is_superuser: false,
  }

  const [formState, setFormState] = useState<IState>(emptyFormState)

  useEffect(() => {
    if (store.sessionStore.checkedSessionOnLoad) {
      if (!store.sessionStore.isAuthenticated) {
        setFormState({
          ...formState,
          redirectToHome: true,
          loaded: true,
        })
      } else {
        if (!formState.loaded) {
          store.apiStore.getUser(userId).then((data) => {
            setFormState({
              ...formState,
              ...data,
              loaded: true,
            })
          }).catch((err) => {
          })
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.sessionStore.checkedSessionOnLoad, store.sessionStore.isAuthenticated, formState.loaded])

  if ( formState.redirectToHome ) {
    return <Redirect to={routes.home.path} />
  }

  const handleTextFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormState({
      ...formState,
      [event.target.name]: event.target.value,
    })
  }

  const handleTickboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.checked
    setFormState({
      ...formState,
      [event.target.name]: value,
    })
  }

  const handleEditUser = (event: SyntheticEvent) => {
    event.preventDefault();
    if (formState.password !== formState.confirm_password) {
      store.apiStore.setError("Password does not match Confirm Password.")
      store.apiStore.requestOver()
      window.scroll(0,0)
      return false
    }
    store.apiStore.editUser(userId, {
      ...formState,
    }).then((data) => {
      setFormState({
        ...formState,
        completed: true,
      })
    }).catch((err) => {
      store.apiStore.handleFormError(err, "There was an error editing the User.")
    })
  }

  const handleContinueEditingClick = (event: SyntheticEvent) => {
    event.preventDefault()
    setFormState(emptyFormState)
  }

  return (
    <Page
      title={ routes.editUser.label }
      iconClasses={ routes.editUser.icon }
      backUrl={ routes.users.path }
      {...props}
    >
      {!formState.completed && (
        <form method="POST" onSubmit={handleEditUser}>
          <div className="form-group required">
            <label htmlFor="id_email" className="control-label">Email</label>
            <input id="id_email" name="email" type="email" className="form-control" value={formState.email} required onChange={handleTextFieldChange} />
          </div>
          <div className="form-group">
            <label htmlFor="id_password" className="control-label">Password</label>
            <input id="id_password" name="password" type="password" className="form-control" value={formState.password} onChange={handleTextFieldChange} />
          </div>
          <div className="form-group">
            <label htmlFor="id_confirm_password" className="control-label">Confirm Password</label>
            <input id="id_confirm_password" name="confirm_password" type="password" className="form-control" value={formState.confirm_password} onChange={handleTextFieldChange} />
          </div>
          <div className="form-group required">
            <label htmlFor="id_first_name" className="control-label">First Name</label>
            <input id="id_first_name" name="first_name" className="form-control" required value={formState.first_name} onChange={handleTextFieldChange} />
          </div>
          <div className="form-group">
            <label htmlFor="id_middle_names" className="control-label">Middle Names</label>
            <input id="id_middle_names" name="middle_names" className="form-control" value={formState.middle_names} onChange={handleTextFieldChange} />
          </div>
          <div className="form-group required">
            <label htmlFor="id_last_name" className="control-label">Last Name</label>
            <input id="id_last_name" name="last_name" className="form-control" required value={formState.last_name} onChange={handleTextFieldChange} />
          </div>
          <div className="form-group form-check">
            <input id="id_is_active" type="checkbox" name="is_active" checked={formState.is_active} className="form-check-input" onChange={handleTickboxChange} />
            <label htmlFor="id_is_active" className="form-check-label">Is Active</label>
          </div>
          <div className="form-group form-check">
            <input id="id_is_staff" type="checkbox" name="is_staff" checked={formState.is_staff} className="form-check-input" onChange={handleTickboxChange} />
            <label htmlFor="id_is_staff" className="form-check-label">Is Data Entry User</label>
          </div>
          <div className="form-group form-check">
            <input id="id_is_superuser" type="checkbox" name="is_superuser" checked={formState.is_superuser} className="form-check-input" onChange={handleTickboxChange} />
            <label htmlFor="id_is_superuser" className="form-check-label">Is Administrator User</label>
          </div>
          <input type="submit" className='btn btn-primary' value="Save" />
        </form>
      )}
      {!!formState.completed && (
        <div className="alert alert-success" role="alert">
          <h4 className="alert-heading"><i className="fas fa-check"></i> Success!</h4>
          <a href="#" onClick={handleContinueEditingClick}>Continue editing</a> or go back to the <Link to={routes.users.path}>User list</Link>
        </div>
      )}
    </Page>
  )
})
