import React from "react";
import {
  withRouter, RouteComponentProps
} from "react-router";

import { RootStoreContext } from './store/Store'
import BaseLayout from './components/BaseLayout'

class App extends React.Component<RouteComponentProps, any> {

  static contextType = RootStoreContext

  componentDidUpdate(prevProps: { location: object }) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChange();
    }
  }

  componentDidMount = () => {
    this.context.apiStore.getSession()
  }

  onRouteChange() {
    this.context.onRouteChange()
  }

  render = () => {
    return (
      <BaseLayout {...this.props} />
    )
  }
}

export default withRouter(App)
