import {
  Route,
  Switch,
} from 'react-router'

import { routes } from '../routes'
import TopNav from './TopNav'
import SideBar from './SideBar'

export default function BaseLayout({
  ...props
}) {
  return (
    <div className='wrapper'>
      <TopNav {...props} />
      <SideBar />
      <Switch>
        {Object.keys(routes).map((key) => {
          const route = routes[key]
          return (
            <Route key={route.path} exact path={route.path}>
              <route.component {...props} />
            </Route>
          )
        })}
      </Switch>
    </div>
  )
}
