import { ReactNode } from 'react'
import { NavLink } from 'react-router-dom'

interface IProps {
  to: ReactNode;
  iconClasses: ReactNode;
  label: ReactNode;
  exact?: boolean;
}

export default function SideBarItem({
  to,
  iconClasses,
  label,
  exact = false,
  ...props
}: IProps) {
  return (
    <li className="nav-item">
      <NavLink to={to} exact={exact} activeClassName="active" className="nav-link">
        <i className={"nav-icon " + iconClasses}></i>
        <p>
          {label}
        </p>
      </NavLink>
    </li>
  )
}
