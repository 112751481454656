import { ITable } from './core'

export const BudCountHistoryTable: ITable = {
    base_url: "/api/bud-counts/history/",
    columns: [
        {
            Header: 'PK',
            accessor: 'pk',
            id: 'pk',
        },
        {
            Header: 'Year',
            accessor: 'bud_count_record.delivery_year.dey_year',
            ordering: 'bud_count_record__delivery_year__dey_year',
        },
        {
            Header: 'Variety',
            accessor: 'bud_count_record.variety.var_description',
            ordering: 'bud_count_record__variety__var_description',
        },
        {
            Header: 'Grow Type',
            accessor: 'bud_count_record.grow_type.gro_description',
            ordering: 'bud_count_record__grow_type__gro_description',
        },
        {
            Header: 'Rootstock',
            accessor: 'bud_count_record.rootstock.roo_description',
            ordering: 'bud_count_record__rootstock__roo_description',
        },
        {
            Header: 'Count',
            accessor: 'bcrh_count',
        },
        {
            Header: 'Bundle No',
            accessor: 'bcrh_bundle_no',
        },
        {
            Header: 'Location',
            accessor: 'bud_location.blo_description',
            ordering: 'bud_location__blo_description',
        },
        {
            Header: 'User',
            accessor: 'user',
            ordering: 'bcrh_zzzsys_user_id',
        },
        {
            Header: 'Created',
            accessor: 'bcrh_created',
        },
    ],
    hiddenColumns: [
        'pk'
    ],
}
