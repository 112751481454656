import { createContext } from "react"

import APIStore from "./APIStore"
import SessionStore from "./SessionStore"

export class RootStore {
  apiStore: APIStore
  sessionStore: SessionStore
  constructor() {
    this.apiStore = new APIStore(this)
    this.sessionStore = new SessionStore(this)
  }
  onRouteChange () {
    this.apiStore.onRouteChange()
    this.sessionStore.onRouteChange()
  }
}

const rootStore = new RootStore()

export default rootStore
export const RootStoreContext = createContext<RootStore>(rootStore)
