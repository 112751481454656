import { makeAutoObservable, runInAction, toJS } from "mobx"
import { isEmpty, omit } from 'lodash'
import queryString from 'query-string'

import { sessionStoreProtectedAttrs } from "./SessionStore"
import { RootStore } from "./Store"
import { ITable } from '../tables/core'

class APIStore {
  rootStore: RootStore
  isBusy: boolean = false
  errors: Array<string> = []
  errorData: object = {}
  constructor(rootStore: RootStore) {
    makeAutoObservable(this, { rootStore: false })
    this.rootStore = rootStore
  }
  newRequest = () => {
    runInAction(() => {
      this.isBusy = true
      this.errors = []
      this.errorData = {}
    })
  }
  requestOver = () => {
    runInAction(() => {
      this.isBusy = false
    })
  }
  handleFormError = (err: any, message: string) => {
    const errors = toJS(this.rootStore.apiStore.errors)
    if (isEmpty(errors)) {
      this.setError(message)
    }
  }
  onRouteChange = () => {
    runInAction(() => {
      this.isBusy = false
      this.errors = []
      this.errorData = {}
    })
  }
  getCSRF = () => {
    this.newRequest()
    return fetch("/api/csrf/", {
      credentials: "same-origin",
    })
    .then((res) => {
      this.requestOver()
      let csrfToken = res.headers.get("X-CSRFToken");
      if (!csrfToken) csrfToken = ""
      this.rootStore.sessionStore.setCSRFToken(csrfToken)
    })
    .catch((err) => {
      this.requestOver()
      this.setError(err)
    });
  }

  getSession = () => {
    this.newRequest()
    fetch("/api/session/", {
      credentials: "same-origin",
    })
    .then((res) => res.json())
    .then((data) => {
      this.requestOver()
      runInAction(() => {
        this.getCSRF().then(() => {
          this.rootStore.sessionStore.checkedSessionOnLoad = true
          if (data.isAuthenticated) {
            this.rootStore.sessionStore.setUserData(data)
          } else {
            this.rootStore.sessionStore.clearUserData()
          }
        }).catch(() => {
          this.rootStore.sessionStore.checkedSessionOnLoad = true
          this.rootStore.sessionStore.clearUserData()
        })
      })
    })
    .catch((err) => {
      this.requestOver()
      this.setError("Server error: Could not retrieve session.")
    });
  }

  whoami = () => {
    this.newRequest()
    fetch("/api/whoami/", {
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "same-origin",
    })
    .then((res) => res.json())
    .then((data) => {
      this.requestOver()
      this.rootStore.sessionStore.setUserData(data)
    })
    .catch((err) => {
      this.requestOver()
      this.setError(err)
    });
  }

  isResponseOk = (response: { status: number, json: any, statusText: string }) => {
    if (response.status >= 200 && response.status <= 299) {
      return response.json();
    } else {
      return response.json().then((data: { errors: string[] }) => {
        if (data.errors) {
          window.scroll(0,0)
          this.setErrors(data.errors)
        }
        this.setErrorData(data)
        throw Error(response.statusText);
      }).catch((err: any) => {
        if (!this.errors) {
          this.setError(String(err))
        }
        window.scroll(0,0)
        throw Error(response.statusText)
      })
    }
  }

  setError ( error: string ) {
    this.errors = [ String(error) ]
  }

  setErrors ( errors: string[] ) {
    this.errors = errors.map((error) => String(error))
  }

  setErrorData( data: object ) {
    this.errorData = data
  }

  apiError () {
    this.setError("There was an error.")
  }

  login = ({email, password}: {email: string, password: string}) => {
    this.newRequest()
    return fetch("/api/login/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": this.rootStore.sessionStore.csrf,
      },
      credentials: "same-origin",
      body: JSON.stringify({email, password}),
    })
    .then(this.isResponseOk)
    .then((data) => {
      this.requestOver()
      this.getCSRF().then(() => {
        this.rootStore.sessionStore.setUserData(data)
      })
    })
    .catch((err) => {
      this.requestOver()
      throw new Error(err)
    });
  }

  logout = () => {
    this.newRequest()
    return fetch("/api/logout", {
      credentials: "same-origin",
    })
    .then(this.isResponseOk)
    .then(() => {
      this.requestOver()
      this.rootStore.sessionStore.clearUserData()
    })
    .catch((err) => {
      this.rootStore.sessionStore.clearUserData()
      this.requestOver()
      throw new Error(err)
    })
  }

  register = (postData: object) => {
    this.newRequest()
    return fetch("/api/register/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": this.rootStore.sessionStore.csrf,
      },
      credentials: "same-origin",
      body: JSON.stringify(postData),
    })
    .then(this.isResponseOk)
    .then((data) => {
      this.requestOver()
      return data
    })
    .catch((err) => {
      this.requestOver()
      throw new Error(err)
    });
  }

  details = (postData: object) => {
    this.newRequest()
    return fetch("/api/details/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": this.rootStore.sessionStore.csrf,
      },
      credentials: "same-origin",
      body: JSON.stringify(omit(postData, [
        ...sessionStoreProtectedAttrs,
        "completed",
        "loaded",
        "email",
        "confirm_password",
      ])),
    })
    .then(this.isResponseOk)
    .then((data) => {
      this.requestOver()
      return data
    })
    .catch((err) => {
      this.requestOver()
      throw new Error(err)
    });
  }

  createUser = (postData: object) => {
    this.newRequest()
    return fetch("/api/create-user/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": this.rootStore.sessionStore.csrf,
      },
      credentials: "same-origin",
      body: JSON.stringify(omit(postData, [
        "completed",
      ])),
    })
    .then(this.isResponseOk)
    .then((data) => {
      this.requestOver()
      return data
    })
    .catch((err) => {
      this.requestOver()
      throw new Error(err)
    });
  }

  searchTable = (table: ITable, params: object) => {
    this.newRequest()
    return fetch(`${table.base_url}?${queryString.stringify(params)}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": this.rootStore.sessionStore.csrf,
      },
      credentials: "same-origin",
    })
    .then(this.isResponseOk)
    .then((data) => {
      this.requestOver()
      return data
    })
    .catch((err) => {
      this.requestOver()
      throw new Error(err)
    });
  }

  getUser = (userId: number) => {
    this.newRequest()
    return fetch(`/api/user/${userId}/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": this.rootStore.sessionStore.csrf,
      },
      credentials: "same-origin",
    })
    .then(this.isResponseOk)
    .then((data) => {
      this.requestOver()
      return data
    })
    .catch((err) => {
      this.requestOver()
      throw new Error(err)
    });
  }

  editUser = (userId: number, postData: object) => {
    this.newRequest()
    return fetch(`/api/user/${userId}/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": this.rootStore.sessionStore.csrf,
      },
      credentials: "same-origin",
      body: JSON.stringify(omit(postData, [
        "completed",
      ])),
    })
    .then(this.isResponseOk)
    .then((data) => {
      this.requestOver()
      return data
    })
    .catch((err) => {
      this.requestOver()
      throw new Error(err)
    });
  }

  budCountSearch = (postData: object) => {
    this.newRequest()
    return fetch(`/api/bud-counts/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": this.rootStore.sessionStore.csrf,
      },
      credentials: "same-origin",
      body: JSON.stringify(postData),
    })
    .then(this.isResponseOk)
    .then((data) => {
      this.requestOver()
      return data
    })
    .catch((err) => {
      this.requestOver()
      throw new Error(err)
    });
  }

  budCountUpdate = (postData: object) => {
    this.newRequest()
    return fetch(`/api/bud-counts/update/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": this.rootStore.sessionStore.csrf,
      },
      credentials: "same-origin",
      body: JSON.stringify(postData),
    })
    .then(this.isResponseOk)
    .then((data) => {
      this.requestOver()
      return data
    })
    .catch((err) => {
      this.requestOver()
      throw new Error(err)
    });
  }

  getLookup = (lookup: string, params: { [key: string]: any }) => {
    const parsedParams = new URLSearchParams(params)
    this.newRequest()
    return fetch(`/api/lookups/${lookup}/?${parsedParams.toString()}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": this.rootStore.sessionStore.csrf,
      },
      credentials: "same-origin",
    })
    .then(this.isResponseOk)
    .then((data) => {
      this.requestOver()
      return data
    })
    .catch((err) => {
      this.requestOver()
      throw new Error(err)
    });
  }

  getDocusignJWTURL = () => {
    this.newRequest()
    return fetch(`/api/docusign/get_jwt_url/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": this.rootStore.sessionStore.csrf,
      },
      credentials: "same-origin",
    })
    .then(this.isResponseOk)
    .then((data) => {
      this.requestOver()
      return data
    })
    .catch((err) => {
      this.requestOver()
      throw new Error(err)
    });
  }

  updateDocusignJWTCode = (JWTCode: string) => {
    this.newRequest()
    return fetch(`/api/docusign/update_jwt_code/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": this.rootStore.sessionStore.csrf,
      },
      credentials: "same-origin",
      body: JSON.stringify({
        jwt_code: JWTCode,
      }),
    })
    .then(this.isResponseOk)
    .then((data) => {
      this.requestOver()
      return data
    })
    .catch((err) => {
      this.requestOver()
      throw new Error(err)
    });
  }

  sendEnvelope = (
    customer_id: string,
    email_to_address: string,
    email_to_name: string,
    subject: string,
    filename: string,
  ) => {
    this.newRequest()
    return fetch(`/api/docusign/send_envelope/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": this.rootStore.sessionStore.csrf,
      },
      credentials: "same-origin",
      body: JSON.stringify({
        customer_id,
        email_to_address,
        email_to_name,
        subject,
        filename,
      }),
    })
    .then(this.isResponseOk)
    .then((data) => {
      this.requestOver()
      return data
    })
    .catch((err) => {
      this.requestOver()
      throw new Error(err)
    });
  }

}

export default APIStore
