import { ITable } from './core'

export const UsersTable: ITable = {
    base_url: "/api/users/",
    to_url: "editUser.path",
    to_url_params: {
        ":id": "pk",
    },
    columns: [
        {
            Header: 'PK',
            accessor: 'pk',
            id: 'pk',
        },
        {
            Header: 'Email',
            accessor: 'email',
        },
        {
            Header: 'First Name',
            accessor: 'first_name',
        },
        {
            Header: 'Last Name',
            accessor: 'last_name',
        },
        {
            Header: 'Active',
            accessor: 'is_active',
        },
        {
            Header: 'Data Entry User',
            accessor: 'is_staff',
        },
        {
            Header: 'Administrator User',
            accessor: 'is_superuser',
        },
    ],
    hiddenColumns: [
        'pk'
    ],
}
