import { observer } from "mobx-react";
import { Fragment, useContext } from "react"
import {
  Link,
} from "react-router-dom";

import { routes } from "../routes"
import { RootStoreContext } from '../store/Store'
import SideBarItem from './SideBarItem'
import './SideBar.css'

export default observer(({
  ...props
}) => {
  /* eslint-disable jsx-a11y/alt-text */
  const store = useContext(RootStoreContext)
  return (
    <aside className="main-sidebar sidebar-dark-primary elevation-4">
      <Link to={ routes.home.path } className="brand-link logo-switch">
        <img src={process.env.PUBLIC_URL + "img/logo.png"} className="brand-image-xs logo-xs" />
        <img src={process.env.PUBLIC_URL + "img/logo.png"} className="brand-image-xl logo-xl large-sidebar-logo" />
      </Link>
      <div className="sidebar">
        <nav className="mt-2">
          <ul className="nav nav-pills nav-sidebar nav-child-indent flex-column" data-widget="treeview" role="menu">
            {store.sessionStore.checkedSessionOnLoad && store.sessionStore.isAuthenticated && (
              <Fragment>
                <li className="nav-header nav-user-info"><strong>{store.sessionStore.email}</strong></li>
              </Fragment>
            )}
            <SideBarItem to={routes.home.path} iconClasses={routes.home.icon} label={routes.home.label} exact={true} />
            {store.sessionStore.checkedSessionOnLoad && store.sessionStore.isAuthenticated && (
              <Fragment>
                <SideBarItem to={routes.details.path} iconClasses={routes.details.icon} label={routes.details.label} />
                {store.sessionStore.isDataEntryUser && (
                  <Fragment>
                    <SideBarItem to={routes.budCountSearch.path} iconClasses={routes.budCountSearch.icon} label={routes.budCountSearch.label} />
                    <SideBarItem to={routes.budCountHistory.path} iconClasses={routes.budCountHistory.icon} label={routes.budCountHistory.label} />
                  </Fragment>
                )}
                {!!store.sessionStore.is_superuser && (
                  <Fragment>
                    <li className="nav-header">ADMINISTRATION</li>
                    <SideBarItem to={routes.createUser.path} iconClasses={routes.createUser.icon} label={routes.createUser.label} />
                    <SideBarItem to={routes.users.path} iconClasses={routes.users.icon} label={routes.users.label} />
                    <SideBarItem to={routes.docusignSendEnvelope.path} iconClasses={routes.docusignSendEnvelope.icon} label={routes.docusignSendEnvelope.label} />
                    <SideBarItem to={routes.docusignConfig.path} iconClasses={routes.docusignConfig.icon} label={routes.docusignConfig.label} />
                  </Fragment>
                )}
                <li className="nav-header"></li>
                <SideBarItem to={routes.logout.path} iconClasses={routes.logout.icon} label={routes.logout.label} />
              </Fragment>
            )}
            {store.sessionStore.checkedSessionOnLoad && !store.sessionStore.isAuthenticated && (
              <Fragment>
                <SideBarItem to={routes.register.path} iconClasses={routes.register.icon} label={routes.register.label} />
                <li className="nav-header"></li>
                <SideBarItem to={routes.login.path} iconClasses={routes.login.icon} label={routes.login.label} />
              </Fragment>
              )}
          </ul>
        </nav>
      </div>
    </aside>
  )
})
