import { useContext, useEffect } from 'react'
import { observer } from 'mobx-react'
import { Redirect } from 'react-router-dom'

import { routes } from '../routes'
import { RootStoreContext } from '../store/Store'

export default observer(({
  ...props
}) => {
  const store = useContext(RootStoreContext)
  if (store.sessionStore.checkedSessionOnLoad) {
    if (store.sessionStore.isAuthenticated) {
      useEffect(() => {
        store.apiStore.logout().catch((err) => {
        })
      })
    }
  }
  return (
    <Redirect to={routes.home.path} />
  )
})
