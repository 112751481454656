import { ReactNode } from 'react'
import './HomeBox.css'

export default function HomeBox({
  heading = <h3>HomeBox Heading</h3>,
  description = "HomeBox Description",
  icon = "fas fa-bath",
  ...props
}: { heading?: ReactNode, description?: ReactNode, icon?: string }) {
  return (
    <div className="info-box">
      <span className="info-box-icon bg-info"><i className={icon}></i></span>
      <div className="info-box-content">
        <span className="info-box-text">{heading}</span>
        <span className="info-box-text box-description">{description}</span>
      </div>
    </div>
  )
}
