import React, { useContext, useState, SyntheticEvent, useEffect } from 'react'
import { observer } from 'mobx-react'
import { Redirect } from 'react-router-dom'

import { routes } from '../routes'
import { RootStoreContext } from '../store/Store'
import Page from '../components/Page'

export interface IState {
  [key: string]: any,
}

export default observer(({
  ...props
}) => {

  /* eslint-disable jsx-a11y/anchor-is-valid */

  const store = useContext(RootStoreContext)

  const emptyFormState = {
    loaded: false,
    completed: false,
    redirectToHome: false,
    customer_id: "",
    email_to_address: "",
    email_to_name: "",
    subject: "",
    filename: "",
  }

  const [formState, setFormState] = useState<IState>(emptyFormState)

  useEffect(() => {
    if (store.sessionStore.checkedSessionOnLoad) {
      if (!store.sessionStore.isAuthenticated) {
        setFormState({
          ...formState,
          redirectToHome: true,
          loaded: true,
        })
      } else {
        if (!formState.loaded) {
          setFormState({
            ...formState,
            loaded: true,
          })
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.sessionStore.checkedSessionOnLoad, store.sessionStore.isAuthenticated, formState.loaded])

  if ( formState.redirectToHome ) {
    return <Redirect to={routes.home.path} />
  }

  const handleTextFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormState({
      ...formState,
      [event.target.name]: event.target.value,
    })
  }

  const handleSelectFieldChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setFormState({
      ...formState,
      [event.target.name]: event.target.value,
    })
  }

  const handleSendEnvelope = (event: SyntheticEvent) => {
    event.preventDefault();
    store.apiStore.sendEnvelope(
      formState.customer_id,
      formState.email_to_address,
      formState.email_to_name,
      formState.subject,
      formState.filename,
    ).then((data) => {
      setFormState({
        ...formState,
        completed: true,
      })
    }).catch((err) => {
      store.apiStore.handleFormError(err, "There was an error sending the Docusign envelope.")
    })
  }

  return (
    <Page
      title={ routes.docusignSendEnvelope.label }
      iconClasses={ routes.docusignSendEnvelope.icon }
      {...props}
    >
      {!formState.completed && (
        <form method="POST" onSubmit={handleSendEnvelope}>
          <div className="form-group required">
            <label htmlFor="id_customer_id" className="control-label">Customer ID</label>
            <input id="id_customer_id" name="customer_id" className="form-control" required onChange={handleTextFieldChange} />
          </div>
          <div className="form-group required">
            <label htmlFor="id_email_to_address" className="control-label">Email To Address</label>
            <input id="id_email_to_address" name="email_to_address" type="email" className="form-control" required onChange={handleTextFieldChange} />
          </div>
          <div className="form-group required">
            <label htmlFor="id_email_to_name" className="control-label">Email To Name</label>
            <input id="id_email_to_name" name="email_to_name" className="form-control" required onChange={handleTextFieldChange} />
          </div>
          <div className="form-group required">
            <label htmlFor="id_subject" className="control-label">Subject</label>
            <input id="id_subject" name="subject" className="form-control" required onChange={handleTextFieldChange} />
          </div>
          <div className="form-group required">
            <label htmlFor="id_filename" className="control-label">Filename</label>
            <select id="id_filename" name="filename" className="form-control" required onChange={handleSelectFieldChange}>
              <option value=""></option>
              <option value="ALMONDS_2022.pdf">ALMONDS_2022.pdf</option>
              <option value="STONEFRUIT_2022.pdf">STONEFRUIT_2022.pdf</option>
              <option value="ALMONDS_2023.pdf">ALMONDS_2023.pdf</option>
              <option value="STONEFRUIT_2023.pdf">STONEFRUIT_2023.pdf</option>
              <option value="ALMONDS_2024.pdf">ALMONDS_2024.pdf</option>
              <option value="STONEFRUIT_2024.pdf">STONEFRUIT_2024.pdf</option>
            </select>
          </div>
          <input type="submit" className='btn btn-primary' value="Send envelope" />
        </form>
      )}
      {!!formState.completed && (
        <div className="alert alert-success" role="alert">
          <h4 className="alert-heading"><i className="fas fa-check"></i> Success!</h4>
          <p>Successfully sent the envelope.</p>
        </div>
      )}
    </Page>
  )
})
